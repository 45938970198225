/*
 * @Descripttion: 请求
 * @version: 1.0.0
 * @Author: 李金深
 * @Date: 2023-01-14 14:04:12
 * @LastEditors: 李金深 2896583081@qq.com
 * @LastEditTime: 2024-11-08 10:34:26
 */
import axios from "axios";
import store from "../store";
// import { Message } from "element-ui";
// import { Toast } from "wc-messagebox";

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "",
  timeout: 50000, // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
  console.log(error);
  if (error.response) {
    // const data = error.response.data;
    // if (error.response.status === 403) {
    //   Message.error(data.message);
    // }
    // // error.response.status === 401 && !(data.result && data.result.isLogin);
    // if (error.response.status === 401) {
    //   Message.error("登录凭证失效，请重新登录");
    //   localStorage.clear("LOGIN_INFO");
    //   store.commit("updateLoginStatus", false);
    //   location.href = "/";
    // }
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  // console.log("请求路径", config.url);
  let prefix = config.url.split("/")[1];
  const LOGIN_INFO = localStorage.getItem("LOGIN_INFO")
    ? JSON.parse(localStorage.getItem("LOGIN_INFO"))
    : "";
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (LOGIN_INFO) {
    if (prefix == "im") {
      config.headers["sessionId"] = LOGIN_INFO.sessionId;
      config.headers["authToken"] = LOGIN_INFO.tripartiteToken;
    } else {
      config.headers["token"] = LOGIN_INFO.token;
    }
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  // || response.data.code == "-1"
  if (response.data.code == 401) {
    // let isMobile = localStorage.getItem("isMobile");
    // if (isMobile == "true") {
    //   Toast("登录凭证失效，请重新登录", {
    //     position: "center", // 'bottom' | 'center',
    //     duration: "1500",
    //   });
    // } else {
    //   Message.error("登录凭证失效，请重新登录");
    // }
    window.$EventBus.$emit("toast", {
      type: "error",
      message: "登录凭证失效，请重新登录",
    });
    store.commit("updateLoginStatus", false);
    localStorage.removeItem("LOGIN_INFO");
    let LuckyDraw = localStorage.getItem("LuckyDraw")
      ? localStorage.getItem("LuckyDraw")
      : ""; // 判断本地是否保存有用户打开抽奖页面的标识
    if (LuckyDraw != "") {
      location.href = "/award";
    } else {
      location.href = "/";
    }
    return;
  }
  return response.data;
}, errorHandler);

export default request;
